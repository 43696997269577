.login-page {
  max-width: 730px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 100px;
  padding: 30px;
}

.login-page h1 {
  text-align: center;
  color: rgb(68, 68, 68);
}

.login-page .login-message {
  color: rgb(68, 68, 68);
  margin: 30px 0;
}

.login-page a {
  color: #c85933;
  font-weight: bold;
  text-decoration: none;
}

.agent-btn {
  font-weight: bold;
}
.agent-form-input {
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  outline: none;
  resize: none;
  box-sizing: border-box;
}

.agent-form-select,
.agent-form-input::placeholder {
  color: #536387;
  font-family: "Playfair Display";
}

.agent-form-input:focus {
  border-color: #c85933;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.agent-form-label {
  color: #07074d;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}

.agent-btn {
  text-align: center;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  padding: 14px 25px;
  border: none;
  font-weight: 500;
  background-color: #c85933;
  color: white;
  cursor: pointer;
  margin-top: 25px;
}
.agent-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
/* forgot password */
.forgot-password {
  padding-top: 10px;
}
@media only screen and (max-width: 600px) {
  .agent-main-wrapper {
    padding: 10px;
  }
  .agent-form-wrapper {
    padding: 15px;
  }
}

.agent-form-input {
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  outline: none;
  resize: none;
  box-sizing: border-box;
}

.agent-form-select,
.agent-form-input::placeholder {
  color: #536387;
  font-family: "Playfair Display";
}

.agent-form-input:focus {
  border-color: #c85933;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.agent-form-label {
  color: #07074d;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}

.agent-btn {
  text-align: center;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  padding: 14px 25px;
  border: none;
  font-weight: 500;
  background-color: #c85933;
  color: white;
  cursor: pointer;
  margin-top: 25px;
}
.agent-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 600px) {
  .agent-main-wrapper {
    padding: 10px;
  }
  .agent-form-wrapper {
    padding: 15px;
  }
}

.disabled-btn {
  opacity: 0.5;
  cursor: not-allowed;
}
